<template>
  <div class="content">
    <el-form inline :model="search" class="tb-form">
      <el-form-item label="优惠券名称：">
        <el-input v-model="search.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="add">
      <el-button class="tb-button" type="primary" @click="add">
        <i class="tb-icon-plus"/>添加优惠券
      </el-button>
    </div>
    <div class="tablebox">
      <el-table
        :data="tableData"
        class="tb-table"
        @row-dblclick="toDetail"
      >
        <el-table-column prop="couponName" label="优惠券名称"/>
        <el-table-column prop="statusname" label="优惠券状态"/>
        <el-table-column prop="startend" label="起止日期"/>
        <el-table-column label="操作">
          <router-link slot-scope="scope" :to="`/coupon/add?id=${scope.row.id}`" class="edit">编辑</router-link>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="tb-pagination"
        layout="prev, pager, next"
        :current-page.sync="page.no + 1"
        :page-size="page.size"
        :total="page.total"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CouponList',
  data() {
    return {
      search: {
        name: ''
      },
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0
      }
    }
  },
  mounted() {
    let path = this.$route.path
    if (path.indexOf('/') === 0) path = path.substring(1)
    const currentPage = {
      link: path,
      name: '优惠券列表'
    }
    this.$store.commit('changeRoute', currentPage)
    this.getList()
  },
  methods: {
    async getList() {
      const {no, size} = this.page
      const {name} = this.search
      const params = {
        page_size: size,
        page_no: no,
        name
      }
      const url = 'admin/coupon/list'
      const data = await this.$https.get(url, {params})
      if (!data) return
      data.list.forEach(item => {
        item.statusname = item.status === 1 ? '已生效' : '未生效'
        item.startend = this.$dayjs(item.startTime).format('YYYY-MM-DD') + '至' + this.$dayjs(item.endTime).format('YYYY-MM-DD')
      })
      this.page.total = data.total_count
      this.tableData = data.list
    },
    onSearch() {
      this.page.no = 0
      this.getList()
    },
    add() {
      this.$router.push('/coupon/add')
    },
    pageChange(no) {
      this.page.no = no - 1
      this.getList()
    },
    toDetail(row) {
      this.$router.push(`/coupon/add?id=${row.id}`)
    }
  }
  
}
</script>

<style scoped lang="scss">
</style>
